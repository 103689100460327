import React, { Component } from "react";
import GameView from "../components/GameView";

export class RoutePlay extends Component {
  render() {
    return <GameView />;
  }
}

export default RoutePlay;
