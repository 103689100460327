import React, { Component } from "react";
import DesignDrawing from "../components/DesignDrawing";

export class RouteDesign extends Component {
  render() {
    return <DesignDrawing />;
  }
}

export default RouteDesign;
