const PRESET_DRAWINGS_BASE_NAME = "Preset Drawings";
const COLOR_PALETTE_MAP = {
  WHITE: "#FFFFFF",
  BLACK: "#1F2937",
  GREY: "#9CA3AF",
  GREEN: "#10B981",
  BLUE: "#60A5FA",
  RED: "#BE185D",
  PINK: "#F87171",
  ORANGE: "#F59E0B",
  PURPLE: "#8B5CF6",
};
const REVERSE_COLOR_PALETTE_MAP = {
  "#FFFFFF": "WHITE",
  "#1F2937": "BLACK",
  "#9CA3AF": "GREY",
  "#10B981": "GREEN",
  "#60A5FA": "BLUE",
  "#BE185D": "RED",
  "#F87171": "PINK",
  "#F59E0B": "ORANGE",
  "#8B5CF6": "PURPLE",
};
const PALETTE_BAR_EMOJIS = [
  "✅",
  "👻",
  "🦀",
  "🐛",
  "🐮",
  "🦁",
  "🐨",
  "🐼",
  "🐶",
  "🐹",
  "🐵",
  "🥳",
  "🐙",
  "🐢",
  "🐈",
  "🍎",
  "🍓",
  "🍒",
  "🍆",
  "🍅",
  "⚽️",
  "🏈",
  "🏀",
  "🎾",
  "🎯",
  "🎲",
  "💙",
  "💜",
  "💚",
  "🤡",
  "💄",
  "🐞",
  "⚡️",
  "✨",
  "🎊",
  "🥳",
  "🎉",
  "🎁",
];
const DEVICE_TYPE = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
};

export {
  DEVICE_TYPE,
  PRESET_DRAWINGS_BASE_NAME,
  COLOR_PALETTE_MAP,
  REVERSE_COLOR_PALETTE_MAP,
  PALETTE_BAR_EMOJIS,
};
