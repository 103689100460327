import React, { Component } from "react";
import LandingPage from "../components/LandingPage";

export class RouteLanding extends Component {
  render() {
    return <LandingPage />;
  }
}

export default RouteLanding;
